<template>
  <v-row class="pa-5">
    <v-col cols="12" lg="9">
      <v-row>
        <v-col cols="12" md="4">
          <v-card flat class="pa-4 text-center" height="100%">
            <v-avatar width="150px" height="150px">
              <v-img :src="g_user.user.avatar" />
            </v-avatar>
            <div class="title my-4 text-left">
              Hi, {{ g_user.person.name }}
              <div class="caption--text">Apa yang kamu mulai?</div>
            </div>
            <div class="d-flex justify-space-between">
              <v-btn
                color="primary"
                depressed
                style="width: 120px"
                class="mr-4"
                @click="openDialogElearning()"
              >
                E-Learning
              </v-btn>
              <v-btn
                color="secondary"
                depressed
                style="width: 120px"
                @click="openDialogEtest()"
              >
                E-Test
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat class="pa-4 mb-4">
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar color="rgba(72, 134, 255, 0.16)" size="65">
                  <v-icon color="#4886FF" size="30">mdi-book-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="subtitle font-weight-black">
                    E-Learning Disimpan
                  </v-list-item-title>
                  <v-list-item-subtitle class="title">
                    {{ data.myElearning }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card flat class="pa-4">
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar color="rgba(227, 177, 5, 0.16)" size="65">
                  <v-icon color="#E3B105" size="30">
                    mdi-clipboard-text
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="subtitle font-weight-black">
                    E-Test Dikerjakan
                  </v-list-item-title>
                  <v-list-item-subtitle class="title">
                    {{ data.myEtest }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat class="pa-4 text-center" height="100%">
            <div class="body-1 mb-2 font-weight-black">
              Nilai E-Test Terbaru
            </div>
            <div v-if="!loadingDiagram">
              <VueApexChart
                type="bar"
                height="200"
                :options="chartOptions"
                :series="chartOptions.series"
              />
            </div>
            <div v-else>Loading ...</div>
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="title font-weight-black">Riwayat E-Test</div>
          <span
            style="cursor: pointer"
            class="blue--text"
            @click="$router.push({ name: 'EtestHistory' })"
          >
            Lihat Semua
          </span>
        </v-col>
        <v-col cols="12">
          <v-card flat class="pa-4">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loadingEtestHistory"
              hide-default-footer
              class="elevation-0"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="3">
      <side-bar-student />
    </v-col>

    <DataDialog
      :model="modelEtest"
      :actionColumn="actionColumnEtest"
      actionLabel="Mulai"
      title="Pilih Etest"
      type="etest"
      @close="closeDialogEtest()"
    />

    <DataDialog
      :model="modelElearning"
      :actionColumn="actionColumnElearning"
      actionLabel="Lihat"
      title="Pilih Elearning"
      type="elearning"
      @close="closeDialogElearning()"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getMyElearning,
  getMyEtest,
  getHistoryEtest,
  getEtestDiagram,
  getLastEtest,
  getLastElearning,
} from "../../api/admin/dashboard";
import { etestHistory } from "@/api/dashboardStudent";
import SideBarStudent from "../../components/SideBarStudent.vue";

export default {
  name: "DashboardStudent",
  components: {
    DataDialog: () => import("@/components/DataDialog"),
    SideBarStudent,
  },
  data() {
    return {
      modelEtest: false,
      loadingEtestHistory: false,
      modelElearning: false,
      actionColumnEtest: false,
      actionColumnElearning: false,
      data: {
        myElearning: 0,
        myEtest: 0,
        etestDiagram: [],
        etestHistory: [],
        lastEtest: [],
        lastElearning: [],
      },
      items: [],
      headers: [
        { text: "Judul", value: "title" },
        { text: "Mata Pelajaran", value: "subject" },
        { text: "Nilai", value: "score" },
        { text: "Poin", value: "point" },
      ],
      loadingDiagram: false,
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#E3B105", "#07294D"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          colors: ["#E3B105", "#07294D"],
          opacity: 1,
        },
        xaxis: {
          categories: [],
        },
        series: [
          {
            name: "Nilai Anda",
            data: [],
          },
          {
            name: "Nilai Tertinggi",
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["g_user"]),
  },
  created() {
    this.loadingDiagram = true;
    this.getEtestHistory();
  },
  async mounted() {
    await getMyElearning().then((res) => {
      this.data.myElearning = res.data.data;
    });
    await getMyEtest().then((res) => {
      this.data.myEtest = res.data.data;
    });
    await getHistoryEtest().then((res) => {
      this.data.etestHistory = res.data.data;
    });

    await getEtestDiagram().then((res) => {
      this.chartOptions.xaxis.categories = [];
      this.data.etestDiagram = res.data.data;
      const d = this.data.etestDiagram;

      d.forEach((e) => {
        this.chartOptions.xaxis.categories.push(e.subject);
        this.chartOptions.series[0].data.push(e.score);
        this.chartOptions.series[1].data.push(e.best_score);
      });
      this.loadingDiagram = false;
    });
    await getLastEtest().then((res) => {
      this.data.lastEtest = res.data.data;
    });
    await getLastElearning().then((res) => {
      this.data.lastElearning = res.data.data;
    });
  },
  methods: {
    openDialogEtest() {
      this.modelEtest = true;
      this.actionColumnEtest = true;
    },
    openDialogElearning() {
      this.modelElearning = true;
      this.actionColumnElearning = true;
    },
    closeDialogEtest() {
      this.modelEtest = false;
      this.actionColumnEtest = false;
    },
    closeDialogElearning() {
      this.modelElearning = false;
      this.actionColumnElearning = false;
    },
    getEtestHistory() {
      this.loadingEtestHistory = true;
      etestHistory({ limit: 5 })
        .then((res) => {
          if (res.data.code) {
            this.items = res.data.data;
          }
        })
        .finally(() => (this.loadingEtestHistory = false));
    },
  },
};
</script>
