import request from "@/utils/request";

export function getMyElearning() {
  return request({
    method: "post",
    url: "/dashboard/my-elearning",
  });
}

export function getMyEtest() {
  return request({
    method: "post",
    url: "/dashboard/my-etest",
  });
}

export function getHistoryEtest() {
  return request({
    method: "post",
    url: "/dashboard/etest-history-list",
  });
}

export function getEtestDiagram() {
  return request({
    method: "post",
    url: "dashboard/etest-diagram",
  });
}

export function getLastEtest() {
  return request({
    method: "post",
    url: "/dashboard/last-etest",
  });
}

export function getLastElearning() {
  return request({
    method: "post",
    url: "/dashboard/last-elearning",
  });
}
